import * as React from "react";
import { FC } from "react";
import classes from "./atelier-list.module.css";

interface IAtelierList {
  children: string[];
}

export const AtelierList: FC<IAtelierList> = ({ children }) => (
  <ul className={classes.list}>
    {children.map((entry, index) => (
      <li key={index}>{entry}</li>
    ))}
  </ul>
);
