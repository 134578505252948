import * as React from "react";
import { FC } from "react";
import * as classnames from "classnames";
import { useLocalized } from "../../common/localization/localize";

import spacingClasses from "../../common/css/spacings.module.css";
import classes from "./hero.module.css";

export const Hero: FC = () => {
  const altText = useLocalized("atelier_hero_alttext");
  return (
    <div className={classnames(classes.hero, spacingClasses.bottomOuterXl)}>
      <img src="/welcome/atelier-anim1.gif" alt={altText} />
    </div>
  );
};
