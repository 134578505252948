import * as React from "react";
import { FC } from "react";
import { ILocalizedPage } from "../common/types/page";
import { useLocalized } from "../common/localization/localize";
import { Hero } from "../components/atelier/hero";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { AtelierList } from "../components/atelier/atelier-list";

import spacingClasses from "../common/css/spacings.module.css";

export const AtelierEntryPoint: FC<ILocalizedPage> = _props => {
  const heading = useLocalized("atelier_title");
  const listContent = [
    useLocalized("atelier_listentry_0"),
    useLocalized("atelier_listentry_1"),
    useLocalized("atelier_listentry_2"),
    useLocalized("atelier_listentry_3"),
    useLocalized("atelier_listentry_4"),
    useLocalized("atelier_listentry_5"),
    useLocalized("atelier_listentry_6"),
    useLocalized("atelier_listentry_7"),
    useLocalized("atelier_listentry_8"),
    useLocalized("atelier_listentry_9"),
    useLocalized("atelier_listentry_10")
  ];
  return (
    <div>
      <Hero />
      <Heading
        className={[spacingClasses.bottomOuterXl]}
        level={1}
        theme={HEADING_CLASS.MAIN}
      >
        {heading}
      </Heading>
      <AtelierList>{listContent}</AtelierList>
    </div>
  );
};
