import * as React from "react";
import { FC } from "react";
import { AtelierEntryPoint } from "../../../entrypoints/atelier";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout lang={LANG.plPL} entrypoint={"atelier"} page={"w_pracowni"}>
    <AtelierEntryPoint lang={LANG.plPL} />
  </DefaultLayout>
);
